import { Backdrop, CircularProgress } from "@mui/material";
import { Component } from "react";

interface InitProps {
}

interface InitState {
}


export class InitPage extends Component<InitProps, InitState> {

    constructor(props: InitProps) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
}