import { Component } from 'react';

import {
  Box,
  Container,
  Fab,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { DMSFormatter } from "../util/DMSFormatter";
import { LatLng } from "leaflet";
import { WebService } from "../util/WebService";
import { Debug } from "../util/Debug";
import { Coords, Game, Participant, Player, PlayerGuess, WMarker } from "../types/Types";
import ImageViewer from 'react-simple-image-viewer';
import { ZoomOutMap } from '@mui/icons-material';
import { WebSocketService } from '../util/WebSocketService';
import { MapGl } from '../util/map/MapGL';
import { ParticipantsList } from '../components/ParticipantsList';
import { Coordinates } from '../components/Coordinates';

type GamePlayerProps = {
  gameOwner: string,
  gameCode: string,
  player?: Player,
  wbService?: WebSocketService,
  setPage: (page: string, skipActive?: boolean) => void
}

type GamePlayerState = {
  savedCoords?: Coords,
  coords?: Coords,
  participants: Participant[],
  game?: Game,
  isImageViewerOpen: boolean
}

export class GamePlayerPage extends Component<GamePlayerProps, GamePlayerState> {
  constructor(props: GamePlayerProps) {
    super(props);
    this.state = {
      participants: [],
      isImageViewerOpen: false
    };
  }

  componentDidMount() {
    this.getPlayersGuess();
    this.getParticipants();
    this.loadGame(this.props.gameCode);
    this.subscribe();
  }

  componentWillUnmount() {
    this.props.wbService?.isConnected && this.props.wbService.unsubscribe('/topic/players');
    this.props.wbService?.isConnected && this.props.wbService.unsubscribe('/topic/games');
  }

  subscribe = () => {
    if (!this.props.wbService?.isConnected) {
      setTimeout(this.subscribe, 500);
      return;
    }
    this.props.wbService?.isConnected && this.props.wbService.subscribe('/topic/players', this.getParticipants);
    this.props.wbService?.isConnected && this.props.wbService.subscribe('/topic/games', () => this.loadGame(this.props.gameCode));
  };

  loadGame = (gameCode: string) => {
    WebService.getGameSafe(gameCode).then(res => {
      this.setState({ game: res });
      if (res.status === 'FINISHED') {
        this.props.setPage("GameResultPage", true);
      }
    });
  }

  getPlayersGuess = () => {
    if (this.props.player?.id == null) {
      return;
    }
    WebService.getPlayerGuess(this.props.gameCode, this.props.player.id).then(res => {
      if (res && res.lat && res.lng) {
        let coords: LatLng = new LatLng(Number(res.lat), Number(res.lng));
        this.setState({ coords: coords });
        this.setState({ savedCoords: coords });
      }
    });
  }

  getParticipants = () => {
    WebService.getParticipants(this.props.gameCode).then(res => this.setState({ participants: res }));
  }

  setPlayerGuess = (coordinates: Coords) => {
    if (this.state.game?.status === "FINISHED") return;
    this.setState({ coords: coordinates });
    this.saveGuess();
  };

  saveGuess = () => {
    if (this.props.player?.id && this.state.coords) {
      let playerGuess: PlayerGuess = { id: this.props.player.id, name: this.props.player.name, lat: this.state.coords.lat.toString(), lng: this.state.coords.lng.toString() };
      WebService.setPlayerGuess(this.props.gameCode, playerGuess).then(res => {
        Debug.log(res);
        this.setState({ savedCoords: this.state.coords });
      });
    }
  }

  showResults = () => {
    this.props.setPage("GameResultPage");
  };

  openImageViewer = () => {
    this.setState({ isImageViewerOpen: true });
  };

  closeImageViewer = () => {
    this.setState({ isImageViewerOpen: false });
  };

  render() {
    let markers: WMarker[] | undefined = undefined;
    if (this.state.coords?.lat && this.state.coords?.lng) {
      markers = [{ key: 'first', coords: { lat: Number(this.state.coords.lat), lng: Number(this.state.coords.lng) }, name: 'Here' }];
    }

    return (
      <Container maxWidth="xl">
        <div style={{ height: 10 }}>

        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Paper sx={{ backgroundColor: 'rgba(255,255,255,0.6)' }}>
              <Box sx={{ padding: 3 }}>
                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                  Where was <Box component='span' sx={{ color: 'secondary.main' }}>{this.props.gameOwner}</Box>?
                </Typography>
              </Box>
              <Box sx={{ padding: 3, paddingTop: 0 }}>
                {this.state.game?.picture &&
                  <div style={{ position: "relative", width: "100%", height: "220px", overflow: "hidden", border: "1px solid #ccc" }}>

                    <img
                      alt='location to guess'
                      src={process.env.REACT_APP_PICTURES_URL + this.state.game?.picture}
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                    {/* <IconButton color="primary" aria-label="zoom picture" sx={{ position: "absolute", top: 10, right: 10}}> */}
                    <Fab size="small" color="primary" aria-label="zoom" sx={{ position: "absolute", top: 10, right: 10 }} onClick={this.openImageViewer} >
                      <ZoomOutMap />
                    </Fab>
                    {/* </IconButton> */}
                  </div>
                }
              </Box>
              <Box sx={{ padding: 3, paddingTop: 0 }}>
              <Coordinates lat={this.state.coords?.lat} lng={this.state.coords?.lng} />
              </Box>
            </Paper>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, margin: 3 }}>
              Participants:
            </Typography>

            <ParticipantsList participants={this.state.participants} />

          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Paper style={{ position: "relative", overflow: 'hidden' }}>

              <MapGl markers={markers} onClick={this.setPlayerGuess} cursor="crosshair" />

            </Paper>
          </Grid>
        </Grid>

        {this.state.isImageViewerOpen && (
          <ImageViewer
            src={[process.env.REACT_APP_PICTURES_URL! + this.state.game?.picture]}
            currentIndex={0}
            onClose={this.closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
              zIndex: 1100
            }}
            closeOnClickOutside={true}
          />
        )}

      </Container>
    );
  }
}