
type StarMarkerProps = {
    color?: string
}

export function StarMarker(props: StarMarkerProps) {

    let color = props.color || '#000';

    return <svg width="24" height="24" viewBox="0 0 24 24">
        <path style={{ fill: color, stroke: '#000000', strokeWidth: 3, paintOrder: 'stroke' }}
            d="M 12,2.982545 C 11.42352,2.9820353 10.902595,3.3262515 10.676997,3.8567558 L 8.7743025,8.3493578 3.899915,8.7638677 C 2.6363522,8.8733032 2.1244244,10.447116 3.0818034,11.278977 l 3.7009815,3.206998 -1.112632,4.765306 c -0.2878922,1.237077 1.0537163,2.21155 2.1411153,1.555191 L 12,18.277338 l 4.188732,2.529134 c 1.087399,0.656359 2.429008,-0.318114 2.141115,-1.555191 l -1.112632,-4.765306 3.700982,-3.206998 C 21.875575,10.447116 21.363648,8.8733036 20.100085,8.7638677 L 15.225698,8.3493578 13.323003,3.8567558 C 13.097406,3.3262515 12.57648,2.9820353 12,2.982545 Z" />
    </svg>;


}