import React, { Component } from 'react';

import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { DMSFormatter } from "../util/DMSFormatter";
import { Game, WMarker, Coords, Participant } from "../types/Types";
import { WebService } from "../util/WebService";
import { ImageUploadField } from '../util/ImageUploadField';
import ImageViewer from 'react-simple-image-viewer';
import { ZoomOutMap } from '@mui/icons-material';
import { WebSocketService } from '../util/WebSocketService';
import { MapGl } from '../util/map/MapGL';
import { ParticipantsList } from '../components/ParticipantsList';
import { Coordinates } from '../components/Coordinates';

type GameOwnerProps = {
  gameCode: string,
  wbService?: WebSocketService,
  setGame: (game: Game) => void,
  setPage: (page: string, skipActive?: boolean) => void
}

type GameOwnerState = {
  coords?: Coords,
  game?: Game,
  locked: boolean,
  participants: Participant[],
  isImageViewerOpen: boolean;
}

export class GameOwnerPage extends Component<GameOwnerProps, GameOwnerState> {

  constructor(props: GameOwnerProps) {
    super(props);
    this.state = {
      locked: false,
      participants: [],
      isImageViewerOpen: false
    };
  }

  componentDidMount() {
    this.loadGame(this.props.gameCode);
    this.getParticipants();
    this.subscribe();
  }

  componentWillUnmount() {
    this.props.wbService?.isConnected && this.props.wbService.unsubscribe('/topic/players');
  }

  subscribe = () => {
    if (!this.props.wbService?.isConnected) {
      setTimeout(this.subscribe, 500);
      return;
    }
    this.props.wbService?.isConnected && this.props.wbService.subscribe('/topic/players', this.getParticipants);
  };

  loadGame = (gameCode: string) => {
    WebService.getGame(gameCode).then(res => {
      this.setState({ game: res });
      if (res.lat && res.lng) {
        this.setState({ coords: {lat: Number(res.lat), lng: Number(res.lng)}});
      }
      if (res && res.status && res.status !== 'NEW') {
        this.setState({ locked: true });
      }
    });
  }

  setGameState = (game: Game) => {
    this.setState({ game: game });

    if (game.lat && game.lng) this.setState({ coords: { lat: Number(game.lat), lng: Number(game.lng) } } );
  }

  getParticipants = () => {
    WebService.getParticipants(this.props.gameCode).then(res => this.setState({ participants: res }));
  }

  setOwnerGuess = (coordinates: Coords) => {
    if (this.state.locked) {
      return;
    }
    this.setState({ coords: coordinates });
    if (this.state.game) {
      let game = this.state.game;
      game.lat = coordinates.lat.toString();
      game.lng = coordinates.lng.toString();
      WebService.updateGame(game).then(gameres => this.props.setGame(gameres));
    }
  };

  startGame = () => {
    if (this.state.game && this.state.game.status === "NEW") {
      let game = this.state.game;
      game.status = "STARTED";
      this.setState({ locked: true })
      WebService.updateGame(game).then(gameres => this.setState({ game: gameres }));
    }
  };

  finishGame = () => {
    if (this.state.game != null && this.state.game.status === "STARTED") {
      let game = this.state.game;
      game.status = "FINISHED";
      WebService.updateGame(game).then(gameres => {
        this.setState({ game: gameres });
        this.props.setPage("GameResultPage", true);
      });
    }
  };

  showResults = () => {
    this.props.setPage("GameResultPage", true);
  };

  openImageViewer = () => {
    this.setState({ isImageViewerOpen: true });
  };

  closeImageViewer = () => {
    this.setState({ isImageViewerOpen: false });
  };

  render() {

    let actionButton;

    if (this.state.game != null && this.state.game.status === "NEW") {
      actionButton = (
        <Button disabled={this.state.coords == null} variant="contained" size="large"
          onClick={this.startGame}>Start Game</Button>
      );
    } else if (this.state.game != null && this.state.game.status === "STARTED") {
      actionButton = (
        <Button variant="contained" size="large" onClick={this.finishGame}>Finish Game</Button>
      );
    } else if (this.state.game != null && this.state.game.status === "FINISHED") {
      actionButton = (
        <Button variant="contained" size="large" onClick={this.showResults}>Show Results</Button>
      );
    } else {
      actionButton = undefined;
    }

    let markers: WMarker[] | undefined = undefined;
    if (this.state.coords?.lat && this.state.coords?.lng) {
      markers = [{key: 'first', coords: {lat: Number(this.state.coords.lat), lng: Number(this.state.coords.lng)}, name: 'Here'}];
    }

    return (
      <Container maxWidth="xl">
        <div style={{ height: 10 }}>

        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Paper sx= {{backgroundColor: 'rgba(255,255,255,0.6)'}}>
            <Box sx={{ padding: 3}}>
            <Typography variant="h5">
                  Upload a picture and mark on the map where it was taken.
            </Typography>
            </Box>
              <Box sx={{ padding: 3, paddingTop: 0  }}>
                {!this.state.game?.picture && <ImageUploadField gameCode={this.props.gameCode} setGame={this.setGameState}></ImageUploadField>}

                {this.state.game?.picture &&
                  <div style={{ position: "relative", width: "100%", height: "220px", overflow: "hidden", borderRadius: 5 }}>

                    <img
                      alt='location to guess'
                      src={process.env.REACT_APP_PICTURES_URL + this.state.game?.picture}
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                    {/* <IconButton color="primary" aria-label="zoom picture" sx={{ position: "absolute", top: 10, right: 10}}> */}
                    <Fab size="small" color="primary" aria-label="zoom" sx={{ position: "absolute", top: 10, right: 10 }} onClick={this.openImageViewer} >
                      <ZoomOutMap />
                    </Fab>
                    {/* </IconButton> */}
                  </div>
                }

              </Box>
              <Box sx={{ padding: 3 }}>
                <Coordinates lat={this.state.coords?.lat} lng={this.state.coords?.lng} />
              </Box>
              <Box sx={{ padding: 3 }}>
                {actionButton}
              </Box>
            </Paper>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, margin: 3 }}>
              Participants:
            </Typography>

            <ParticipantsList participants={this.state.participants} />
            
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Paper sx={{ position: "relative", overflow: 'hidden' }}>

              <MapGl markers={markers} onClick={this.setOwnerGuess} cursor='crosshair' />

            </Paper>
          </Grid>
        </Grid>

        {this.state.isImageViewerOpen && (
          <ImageViewer
            src={[process.env.REACT_APP_PICTURES_URL! + this.state.game?.picture]}
            currentIndex={0}
            onClose={this.closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
              zIndex: 1100
            }}
            closeOnClickOutside={true}
          />
        )}

      </Container>
    );
  }
}