export abstract class Debug {
  // for now enable only on development
  static debug = process.env.NODE_ENV === "development";
  // static debug = false;

  static log(message?: any, ...optionalParams: any[]){
    if (Debug.debug) {
      console.log(message, optionalParams);
    }
  }

}