import { Component } from 'react';
import { AuthPage } from "./AuthPage";
import { GameSelectPage } from "./GameSelectPage";
import { PlayerPage } from "./PlayerPage";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { AccountCircle, ArrowBackIosNew } from "@mui/icons-material";
import { WebService } from "../util/WebService";
import { Game, Player } from "../types/Types";
import { GameOwnerPage } from "./GameOwnerPage";
import { GamePlayerPage } from "./GamePlayerPage";
import { GameResultPage } from "./GameResultPage";
import { InitPage } from './InitPage';
import { WebSocketService } from '../util/WebSocketService';

interface IProps {
}

interface IState {
  activePage: string,
  accessCode: string,
  idCode: string,
  player?: Player,
  activeGame?: Game,
  navigation: string[],
  webSocketService?: WebSocketService
}


export class GameView extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      activePage: 'InitPage',
      accessCode: '',
      idCode: '',
      navigation: [],
      webSocketService: WebSocketService.getInstance()
    };
  }

  componentDidMount() {
    this.state.webSocketService && this.state.webSocketService.connect(() => {}, () => {}, () => {});


    let initState = WebService.init();
    // page access code
    this.setState({ accessCode: initState.accessCode });
    // player ID
    this.setState({ idCode: initState.idCode });

    if (initState.accessCode.trim() === '') {
      this.setPage('AuthPage');
      return;
    }

    if (initState.idCode.trim() === '') {
      this.setPage('PlayerPage');
      return;
    }

    if (initState.idCode.trim() !== '') {
      WebService.getPlayer(initState.idCode)
        .then(player => {
          this.setState({ player: player });
          this.setPage('GameSelectPage');
        }).catch((error) => {
          this.setPage('PlayerPage');
        });
    }

  }

  setPage = (page: string, skipActive?: boolean) => {
    if (!skipActive) this.state.navigation.push(this.state.activePage);
    this.setState({ activePage: page });
  }

  setPlayer = (player: Player) => {
    let idCode = player.idCode == null ? '' : player.idCode;
    this.setState({ player: player });
    this.setState({ idCode: idCode });
  }

  setGame = (game: Game) => {
    this.setState({ activeGame: game });
  }

  navigateBack = () => {
    let previousPage = this.state.navigation.pop();
    if (previousPage !== undefined) {
      this.setState({ activePage: previousPage });
    }
  }

  render() {
    let gameOwnerName = '';
    if (this.state.activeGame != null &&
      this.state.activeGame.creator != null &&
      this.state.activeGame.creator.name != null) {
      gameOwnerName = this.state.activeGame.creator.name;
    }

    let gameCode = '';
    if (this.state.activeGame != null &&
      this.state.activeGame.code != null) {
      gameCode = this.state.activeGame.code;
    }

    let page;
    switch (this.state.activePage) {
      case 'InitPage':
        page = <InitPage />
        break;
      case 'AuthPage':
        page = <AuthPage value={this.state.accessCode} setPage={this.setPage} />
        break;
      case 'PlayerPage':
        page = <PlayerPage value={this.state.idCode} setPlayer={this.setPlayer} setPage={this.setPage} />
        break;
      case 'GameSelectPage':
        page = <GameSelectPage wbService={this.state.webSocketService} player={this.state.player} setGame={this.setGame} setPage={this.setPage} />
        break;
      case 'GameOwnerPage':
        page = <GameOwnerPage wbService={this.state.webSocketService} gameCode={gameCode} setGame={this.setGame} setPage={this.setPage} />
        break;
      case 'GamePlayerPage':
        page = <GamePlayerPage wbService={this.state.webSocketService} gameOwner={gameOwnerName} gameCode={gameCode} player={this.state.player} setPage={this.setPage} />
        break;
      case 'GameResultPage':
        page = <GameResultPage gameCode={gameCode} />
        break;
      default:
        page = <p>Error!</p>
    }

    let backbutton = undefined;
    if (this.state.navigation.length > 1) {
      backbutton =
        (<IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={this.navigateBack}
        >
          <ArrowBackIosNew />
        </IconButton>
        );
    }

    let profileIcon = undefined;
    let profile = undefined;
    if (this.state.player !== undefined) {
      profileIcon = (
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-haspopup="false"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      );
      profile = (<Typography variant="h6" component="div" sx={{ flexGrow: 0 }}>
        {this.state.player?.name}
      </Typography>
      );
    }

    return (
      <div id="gameView">
        <AppBar position="static">
          <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: 64, display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <Typography variant="h5" component="div">
              Where Was I?
            </Typography>
          </div>

          <Toolbar>
            {backbutton}
            <Typography variant="h5" component="div" sx={{ flexGrow: 0 }}>
              {process.env.NODE_ENV === "development" && process.env.NODE_ENV}
            </Typography>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>

            </Typography>
            {profileIcon}
            {profile}
          </Toolbar>
        </AppBar>
        {page}
        <div style={{height: '10px'}}></div>
      </div>
    );
  }
}