import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import {GameView} from "./Pages/GameView";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1e9e61',
    },
    secondary: {
      main: '#0488d0',
    },
  },
  // typography: {
  //   fontFamily: `"Tragic Marker", "Helvetica", "Arial", "sans-serif"`
  // }
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GameView />
      </ThemeProvider>
    </div>
  );
}

export default App;
