import { Typography } from "@mui/material";
import React from "react";
import { DMSFormatter } from "../util/DMSFormatter";

type CoordinatesProps = {
    lat?: number,
    lng?: number
}

export function Coordinates(props: CoordinatesProps) {

    let coordinates;
    if (props.lat == null || props.lng == null) {
      coordinates = (
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Not set yet
        </Typography>
      );
    } else {
      let coorStr: string[] = DMSFormatter.formatToArray(props.lat, props.lng);
      coordinates = [
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {coorStr[0]}
        </Typography>,
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {coorStr[1]}
      </Typography>];
    }

    return <React.Fragment>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Coordinates:
        </Typography>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {coordinates}
        </Typography>
    </React.Fragment>;

}