import React, { Component } from "react";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Button } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { Debug } from "./Debug";
import { WebService } from "./WebService";
import { Game } from "../types/Types";

type ImageUloadFieldProps = {
  gameCode: string
  setGame: (game: Game) => void
}

type ImageUloadFieldState = {
  dragOver: boolean
}


export class ImageUploadField extends Component<ImageUloadFieldProps, ImageUloadFieldState> {

  constructor(props: ImageUloadFieldProps) {
    super(props);
    this.state = {
      dragOver: false
    };
  }

  handleFiles = (fl?: FileList | null) => {
    if (fl == null || fl.length === 0) {
      return;
    }
    Debug.log(fl);
    let file = fl.item(0);
    if (file != null) {
      WebService.uploadPicture(this.props.gameCode, file).then(res => {
        this.props.setGame(res);
      });
    }
  };

  handleClick = () => {
    let fileElement: HTMLInputElement = document.getElementById('fileInput') as HTMLInputElement;
    fileElement.click();
  };

  onFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    this.handleFiles(files);
  };

  onDrop = (e: React.DragEvent) => {
    e.preventDefault();
    this.setState({ dragOver: false });
    let dt = e.dataTransfer;
    this.handleFiles(dt.files);
  };

  onDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    this.setState({ dragOver: true });
  };

  onDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    this.setState({ dragOver: false });
  };

  onDrag = (e: React.DragEvent) => {
    e.preventDefault();
  };

  render() {
    return (
      <div 
      style={{ position: "relative", width: "100%", height: "220px", border: '1px solid #0488d0', borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}
      >
        <p style={{color: "#ccc"}}>Drag & Drop</p>

          {!this.state.dragOver && <InsertPhotoIcon sx={{ color: "#0488d0", fontSize: 100 }} />}
          {this.state.dragOver && <Upload sx={{ color: "#0488d0", fontSize: 100 }} />}

        <Button variant="outlined" color="secondary">Choose picture</Button>
        <input type="file" id="fileInput" onChange={this.onFileInput} />
        <div
              style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              onDragEnter={this.onDragEnter}
              onDrag={this.onDrag}
              onDragOver={this.onDrag}
              onDrop={this.onDrop}
              onDragLeave={this.onDragLeave}
              onClick={this.handleClick}
        ></div>
      </div>
    );
  };
}