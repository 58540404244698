import { Paper, Typography } from "@mui/material"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from "react"
import { Participant } from "../types/Types"

type ParticipantsListProps = {
    participants?: Participant[]
}

export function ParticipantsList(props: ParticipantsListProps) {

    return <React.Fragment>

        {props.participants && props.participants.map(
            participant => (
            <Paper key={participant.name} sx={{ margin: 1, backgroundColor: 'rgba(255,255,255,0.6)', display: 'flex', alignItems: 'center'}}>
                <Typography variant="h6" sx={{ flexGrow: '1'}}>{participant.name}</Typography>
                {participant.voted === false &&  <RadioButtonUncheckedIcon color="secondary" />}
                {participant.voted === true &&  <CheckCircleIcon color="primary" />}
            </Paper>
            )
        )
    }

    </React.Fragment>

}