import { Component } from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Container, Grid, Typography } from "@mui/material";
import { WebService } from "../util/WebService";
import { Game, Player } from "../types/Types";
import Moment from 'react-moment';
import { WebSocketService } from '../util/WebSocketService';

interface GameSelectProps {
  player?: Player,
  wbService?: WebSocketService,
  setPage: (page: string) => void
  setGame: (game: Game) => void
}

interface GameSelectState {
  gameCode: string
  selectableGameList: Game[]
}

export class GameSelectPage extends Component<GameSelectProps, GameSelectState> {

  constructor(props: GameSelectProps) {
    super(props);
    this.state = {
      gameCode: '',
      selectableGameList: []
    };
  }

  componentDidMount() {
    this.getGameList();
    this.subscribe();
  }

  componentWillUnmount() {
    this.props.wbService?.isConnected && this.props.wbService.unsubscribe('/topic/games');
  }

  subscribe = () => {
    if (!this.props.wbService?.isConnected) {
      setTimeout(this.subscribe, 500);
      return;
    }
    this.props.wbService?.isConnected && this.props.wbService.subscribe('/topic/games', this.getGameList);
  };

  getGameList = () => {
    WebService.getGameList().then(result => this.setState({ selectableGameList: result }));
  }

  createGame = () => {
    if (this.props.player != null) {
      WebService.createGame(this.props.player).then(game => {
        this.props.setGame(game);
        this.props.setPage("GameOwnerPage");
      });
    }
  }

  getGame = () => {
    this.goToGame(this.state.gameCode);
  }

  goToGame = (gamecode: string) => {
    WebService.getGameSafe(gamecode).then(game => {
      if (game == null ||
        game.creator == null ||
        game.creator.id == null) {
        throw new Error("game could not be loaded");
      }
      if (game.creator.id === this.props.player?.id) {
        this.props.setGame(game);
        this.props.setPage("GameOwnerPage");
      } else {
        this.props.setGame(game);
        this.props.setPage("GamePlayerPage");
      }
    });
  }

  goToResult = (gamecode: string) => {
    WebService.getGameSafe(gamecode).then(game => {
      if (game == null ||
        game.creator == null ||
        game.creator.id == null) {
        throw new Error("game could not be loaded");
      }
      this.props.setGame(game);
      this.props.setPage("GameResultPage");
    });
  }

  render() {

    let gamelist = this.state.selectableGameList.slice(0).reverse().map((game) => {
      let goto = undefined;
      let cardAction = undefined;

      if (game.creator.id === this.props.player?.id && game.status !== 'FINISHED') {
        cardAction = () => this.goToGame(game.code);
        goto = <Button size="medium" variant='outlined' onClick={() => this.goToGame(game.code)} >Play</Button>;
      } else if (game.status === 'STARTED') {
        cardAction = () => this.goToGame(game.code);
        goto = <Button size="medium" variant='outlined' onClick={() => this.goToGame(game.code)} >Play</Button>;
      } else if (game.status === 'FINISHED') {
        cardAction = () => this.goToResult(game.code);
        goto = <Button size="medium" variant='outlined' onClick={() => this.goToResult(game.code)} >Results</Button>;
      }

      let picture = game.picture ? process.env.REACT_APP_PICTURES_URL! + "min_" + game.picture : process.env.REACT_APP_PICTURES_URL! + "placeholder.jpg";

      let chip = undefined;
      if (game.status === 'NEW' || game.status === 'STARTED') {
        chip = <Chip label={game.status} color="primary" sx={{ position: 'absolute', top: 160, right: 10 }} />;
      } else {
        chip = <Chip label={game.status} color="secondary" sx={{ position: 'absolute', top: 160, right: 10 }} />;
      }

      return (
        <Grid item xs={12} sm={12} md={4} key={game.code}>
          <Card sx={{ position: 'relative', maxWidth: 345, backgroundColor: 'rgba(255,255,255,0.6)' }}>
            <CardActionArea onClick={cardAction}>
              <CardMedia
                component="img"
                height="210"
                image={picture}
                alt="location"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {game.creator?.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">

                  <Moment format="DD.MM.YYYY HH:mm:ss">{game.createdOn}</Moment>
                </Typography>
              </CardContent>
              {chip}
            </CardActionArea>
            <CardActions>
              {goto}
            </CardActions>
          </Card>
        </Grid>
      );
    });

    return (
      <Container maxWidth="lg">
        <Grid container spacing={2} margin={2}>
          <Grid item xs={12} sm={12} md={4}>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Button variant="contained" size="large" onClick={this.createGame}>New Game</Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
          </Grid>
        </Grid>
        <Grid container spacing={2} margin={2}>
          {gamelist}
        </Grid>
      </Container>
    );

  }
}