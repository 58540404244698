
type NiceMarkerProps = {
    color?: string,
    name?: string
}

export function NiceMarker(props: NiceMarkerProps) {

    let color = props.color || '#000';
    let name = props.name || ' ';

    return <svg width="200" height="65" viewBox="0 0 200 68">
        <path style={{ fill: color, stroke: '#000000', strokeWidth: 3, paintOrder: 'stroke' }} d="m 89.48547,59.219872 c -1.61799,-1.088808 -2.02391,-3.319676 1.37037,-2.613502 l 4.19152,1.041942 -0.44243,-1.736644 c -0.72267,-2.966845 -1.66095,-5.877092 -2.91515,-8.649418 -2.38803,-5.292066 -5.67563,-9.807958 -9.13029,-14.431077 -1.36593,-1.84773 -0.07283,-3.096082 1.477941,-1.760487 3.008589,2.13263 6.87585,5.52967 10.408425,11.707996 3.532576,6.178326 3.749344,8.985179 4.439984,11.21983 l 1.42914,-3.158091 c 0.74969,-2.523179 2.93076,-2.191836 2.83666,0.622058 -0.12042,3.639332 -1.16893,7.390867 -2.08688,10.873524 -0.99545,3.203389 -0.19687,3.118188 -3.51751,1.429383 -2.73181,-1.419993 -5.48473,-2.814862 -8.06231,-4.545374" />
        <text style={{ fill: color, stroke: '#000000', strokeWidth: 3, paintOrder: 'stroke', fontStyle: 'normal', fontVariant: 'normal', fontWeight: 'normal', fontStretch: 'normal', fontSize: '26pt', lineHeight: 1.25, fontFamily: 'Tragic Marker', textAlign: 'center', textAnchor: 'middle' }} x="89.208633" y="28.59053">
            <tspan x="89.208633" y="28.59053">{name}</tspan>
        </text>
    </svg>;

}