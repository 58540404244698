import { Component } from 'react';

import { Container, Fab, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { WebService } from '../util/WebService';
import { Debug } from '../util/Debug';
import { GameResults, WLine, WMarker } from '../types/Types';
import { toLatLng, toOptimalLatLng } from '../util/HelperFunctions';
import { MapGl } from '../util/map/MapGL';
import ImageViewer from 'react-simple-image-viewer';
import { ZoomOutMap } from '@mui/icons-material';

type GameResultProps = {
  gameCode: string
}

type GameResultState = {
  result?: GameResults,
  isImageViewerOpen: boolean
}

export class GameResultPage extends Component<GameResultProps, GameResultState> {

  constructor(props: GameResultProps) {
    super(props);
    this.state = {
      isImageViewerOpen: false
    };
  }

  componentDidMount() {
    WebService.getGameResults(this.props.gameCode).then(res => {
      Debug.log(res);
      this.setState({ result: res });
    });
  }

  openImageViewer = () => {
    this.setState({ isImageViewerOpen: true });
  };

  closeImageViewer = () => {
    this.setState({ isImageViewerOpen: false });
  };

  render() {

    let starmarker: WMarker | undefined = undefined;

    let markers: WMarker[] = [];

    if (this.state.result) {
      starmarker = { key: this.state.result.creator, coords: toLatLng(this.state.result.lat, this.state.result.lng), color: 'gold' };
    }

    if (this.state.result && this.state.result.guesses && this.state.result.lng) {
      let originLng = this.state.result.lng;
      this.state.result.guesses.forEach((guess, index) => {
        let color = "hsl(" + Math.floor(Math.random() * 361) + ", 100%, 40%)";
        markers.push(
          {
            key: guess.id, coords: toOptimalLatLng(originLng, guess.lat, guess.lng),
            name: guess.name,
            color: color
          }
        )
      });
    }

    let lines: WLine[] = [];

    if (markers.length > 0 && starmarker) {
      let origin = starmarker;
      for (let i = 0; i < markers.length; i++) {
        let marker = markers[i];
        lines.push(
          {
            key: marker.key,
            coords: [{ lat: origin.coords.lat, lng: origin.coords.lng }, { lat: marker.coords.lat, lng: marker.coords.lng }]
          }
        );
      }
    }

    return (
      <Container maxWidth="xl">
        <div style={{ height: 10 }}>

        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            {this.state.result?.picture &&
              <Paper sx={{ backgroundColor: 'rgba(255,255,255,0.6)', marginBottom: 1, overflow: "hidden" }}>
              <div style={{ position: "relative", width: "100%", height: "220px" }}>

                <img
                  alt='location to guess'
                  src={process.env.REACT_APP_PICTURES_URL + this.state.result?.picture}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <Fab size="small" color="primary" aria-label="zoom" sx={{ position: "absolute", top: 10, right: 10 }} onClick={this.openImageViewer} >
                  <ZoomOutMap />
                </Fab>
              </div>
              </Paper>
            }
            <TableContainer component={Paper} sx={{ backgroundColor: 'rgba(255,255,255,0.6)' }}>
              <Table sx={{ minWidth: 50 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Player</TableCell>
                    <TableCell>Distance</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.result && this.state.result.guesses.map((guess, index) =>
                    <TableRow key={guess.id}>
                      <TableCell>{guess.name}</TableCell>
                      <TableCell>{guess.distance && (guess.distance / 1000.0).toFixed(2)} km</TableCell>
                      <TableCell>
                        {index === 0 && <EmojiEventsIcon sx={{ color: "gold" }} />}
                        {index === 1 && <EmojiEventsIcon sx={{ color: "silver" }} />}
                        {index === 2 && <EmojiEventsIcon sx={{ color: "#CD7F32" }} />}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            <Paper style={{ position: "relative", overflow: 'hidden' }}>

              <MapGl starmarker={starmarker} markers={markers} lines={lines}></MapGl>

            </Paper>
          </Grid>

        </Grid>

        {this.state.isImageViewerOpen && (
          <ImageViewer
            src={[process.env.REACT_APP_PICTURES_URL! + this.state.result?.picture]}
            currentIndex={0}
            onClose={this.closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
              zIndex: 1100
            }}
            closeOnClickOutside={true}
          />
        )}
      </Container>
    );
  }
}