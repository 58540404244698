import {Component} from 'react';

import {Button, Container, Stack, TextField} from "@mui/material";
import {WebService} from "../util/WebService";
import {Player} from "../types/Types";

interface PlayerProps {
  value: string,
  setPlayer: (player: Player) => void,
  setPage: (page: string) => void
}

interface PlayerState {
  playerName: string,
  idCode: string
}

export class PlayerPage extends Component<PlayerProps, PlayerState> {

  constructor(props: PlayerProps) {
    super(props);
    this.state = {
      playerName: '',
      idCode: this.props.value
    };
  }

  componentDidUpdate(prevProps: Readonly<PlayerProps>) {
    if (prevProps.value !== this.props.value) {
      this.setState({idCode: this.props.value})
    }
  }

  createPlayer = () => {
    let name = this.state.playerName;
    WebService.createPlayer(name).then(player => {
      this.props.setPlayer(player);
      this.props.setPage("GameSelectPage");
    });
  }

  getPlayer = () => {
    let idCode = this.state.idCode;
    WebService.getPlayer(idCode).then(player => {
      this.props.setPlayer(player);
      this.props.setPage("GameSelectPage");
    });
  }

  render() {
    return (
        <Container maxWidth="sm">
          <Stack spacing={2}>

            <h3>Who are you?</h3>
            <TextField
                label="Name"
                variant="outlined"
                value={this.state.playerName}
                onChange={
                  event => this.setState({playerName: event.target.value})
                }
            />
            <Button variant="contained" size="large" onClick={this.createPlayer}>Join</Button>
          </Stack>
        </Container>
    );
  }
}