import React from "react";
import { Layer, LayerProps, Source, SourceProps } from "react-map-gl";
import { Coords } from "../../types/Types";
import { Feature } from "geojson";

type LinelayerProps = {
    ukey: string,
    coords: Coords[][],
    color?: string,
    width?: number
}

// type LinelayerState = {
// }


export function LineLayer(props: LinelayerProps) {
    // const [viewState, setViewState] = useState<LinelayerState>({});

    let sourceId = props.ukey;

    let features: Feature[] = [];

    props.coords.forEach(coordPair => {
        let feature: Feature = {
            'type': 'Feature',
            'properties': {},
            'geometry': {
                'type': 'LineString',
                'coordinates': [
                    [coordPair[0].lng, coordPair[0].lat],
                    [coordPair[1].lng, coordPair[1].lat]
                ]
            }
        };
        features.push(feature);
    });

    
    let source: SourceProps = {
        id: sourceId,
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: features
        }
    };

    let lineLayer: LayerProps = {
        id: 'lines_' + props.ukey,
        type: 'line',
        source: sourceId,
        layout: {
            'line-join': 'round',
            'line-cap': 'round'
        },
        paint: {
            'line-color': props.color || 'black',
            'line-width': props.width || 1,
            'line-dasharray': [5, 5]
        }
    };

    return <React.Fragment>
        <Source {...source} />
        <Layer {...lineLayer} />
    </React.Fragment>;
}