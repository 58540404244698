import { Coords } from "../types/Types";

export function toLatLng(lat: any, lng: any):Coords {

    let nlat = Number(lat);
    let nlng = Number(lng);

      while(nlng < -180){
        nlng +=360;
      }
      while (nlng > 180){
        nlng -= 360;
      }

    return {lat: nlat, lng: nlng};
}

export function toOptimalLatLng (originlng: any, lat: any, lng: any):Coords {
    let olng = Number(originlng);
    let nlat = Number(lat);
    let nlng = Number(lng);

    // normalize first
    while(nlng < -180){
      nlng += 360;
    }
    while (nlng > 180){
      nlng -= 360;
    }

    // calculate two other options
    let less = nlng - 360;
    let more = nlng + 360;

    let possible = [less, nlng, more];
    // sort by distance
    possible.sort((a, b) => Math.abs(olng - a) - Math.abs(olng - b));
    
    // return option with smallest distance
    return {lat: nlat, lng: possible[0]};
  };