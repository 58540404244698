import {Component} from 'react';
import {TextField, Stack, Button, Container} from "@mui/material";
import {WebService} from "../util/WebService";

import {Debug} from "../util/Debug";

type IProps = {
  value: string,
  setPage: (page: string) => void
}

type IState = {
  inputValue: string;
  errorState: boolean
}

export class AuthPage extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      inputValue: this.props.value,
      errorState: false
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.value !== this.props.value) {
      this.setState({inputValue: this.props.value})
    }
  }

  errorOrContinue(statusCode: number) {
    if (statusCode === 204) {
      this.props.setPage("PlayerPage");
    } else {
      Debug.log(statusCode);
      this.setState({errorState: true});
    }
  }

  checkAuth() {
    this.setState({errorState: false})
    WebService.validateAccessCode(this.state.inputValue)
    .then(res => { this.errorOrContinue(res.status) })
    .catch(() => { this.errorOrContinue(401) });
  }

  render() {
    return (
        <Container maxWidth="sm">
          <Stack spacing={2}>
            <h2>Acces Code</h2>
            <TextField
                error={this.state.errorState}
                variant="outlined"
                value={this.state.inputValue}
                onChange={
                  event => this.setState({inputValue: event.target.value})
                }
            />
            <Button variant="contained" size="large" onClick={() => this.checkAuth()}>Enter</Button>
          </Stack>
        </Container>
    );
  }
}