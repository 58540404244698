import axios from "axios";
import {Debug} from "./Debug";
import {Game, GameResults, InitState, Participant, Player, PlayerGuess} from "../types/Types";

export abstract class WebService {
  static baseUrl = process.env.REACT_APP_API_URL;
  static accessCode = "";
  static urlParams = new URLSearchParams(window.location.search);

  static init(): InitState {
    let initState: InitState = { 
      accessCode: WebService.getAccessCode(), 
      idCode: WebService.getidCode()
    }; 

    return initState;
  }

  static getAccessCode(): string{
    let accessCode = WebService.urlParams.get('accessCode');

    if (accessCode == null || accessCode.trim() === ''){
      accessCode = WebService.getCookie("accessCode");
    }

    if (accessCode == null || accessCode.trim() === ''){
      accessCode = '';
    }

    WebService.accessCode = accessCode;
    this.setCookie("accessCode", accessCode, 7);
    return accessCode;
  }

  static getidCode(): string{
    let idCode = WebService.urlParams.get('idCode');

    if (idCode == null || idCode.trim() === ''){
      idCode = WebService.getCookie("idCode");
    }

    if (idCode == null || idCode.trim() === ''){
      idCode = '';
    }
    return idCode;
  }

  static async validateAccessCode(accessCode: string) {
    return axios.get(WebService.baseUrl + "/verification", {
          headers: {
            "pw": accessCode
          },
        }
    ).then(result => {
      this.setCookie("accessCode", accessCode, 7);
      this.accessCode = accessCode;
      return result;
    });
  }

  static async createPlayer(name: string): Promise<Player> {
    let player: Player = {name: name};
    return axios.post<Player>(WebService.baseUrl + "/players",
        player,
        {
          headers: {
            "Content-Type": "application/json",
            'pw': this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        if (result.data != null && result.data.idCode !== undefined) {
          this.setCookie("idCode", result.data.idCode, 365);
        }
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while creating player");
    });
  }

  static async getPlayer(idCode: string): Promise<Player> {
    return axios.get<Player>(WebService.baseUrl + "/players/" + idCode,
        {
          headers: {
            "Content-Type": "application/json",
            'pw': this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        if (result.data != null && result.data.idCode !== undefined) {
          this.setCookie("idCode", result.data.idCode, 365);
        }
        Debug.log(result);
        return result.data;
      }
      throw new Error("user not found");
    });
  }

  static async createGame(owner: Player): Promise<Game> {
    return axios.post<Game>(WebService.baseUrl + "/games",
        owner,
        {
          headers: {
            "Content-Type": "application/json",
            "pw": this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while creating game");
    });
  }

  static async updateGame(game: Game): Promise<Game> {
    return axios.put<Game>(WebService.baseUrl + "/games/" + game.code,
        game,
        {
          headers: {
            "Content-Type": "application/json",
            "pw": this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while updating game");
    });
  }

  static async getGameList(): Promise<Game[]> {
    return axios.get<Game[]>(WebService.baseUrl + "/games",
        {
          headers: {
            "Content-Type": "application/json",
            'pw': this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while creating game");
    });
  }

  static async getGame(gameCode: string): Promise<Game> {
    return axios.get<Game>(WebService.baseUrl + "/games/" + gameCode,
        {
          headers: {
            "Content-Type": "application/json",
            'pw': this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while creating game");
    });
  }

  static async getGameSafe(gameCode: string): Promise<Game> {
    return axios.get<Game>(WebService.baseUrl + "/games/" + gameCode + "/safe",
        {
          headers: {
            "Content-Type": "application/json",
            'pw': this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while creating game");
    });
  }

  static async setPlayerGuess(gameCode: string, playerGuess: PlayerGuess): Promise<PlayerGuess> {
    return axios.put<PlayerGuess>(WebService.baseUrl + "/games/" + gameCode + "/players/" + playerGuess.id + "/guess",
        playerGuess,
        {
          headers: {
            "Content-Type": "application/json",
            "pw": this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while updating game");
    });
  }

  static async getPlayerGuess(gameCode: string, playerId: string): Promise<PlayerGuess> {
    return axios.get<PlayerGuess>(WebService.baseUrl + "/games/" + gameCode + "/players/" + playerId + "/guess",
        {
          headers: {
            "Content-Type": "application/json",
            "pw": this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while updating game");
    });
  }

  static async getParticipants(gameCode: string): Promise<Participant[]> {
    return axios.get<Participant[]>(WebService.baseUrl + "/games/" + gameCode + "/participants",
        {
          headers: {
            "Content-Type": "application/json",
            "pw": this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while updating game");
    });
  }

  static async getGameResults(gameCode: string): Promise<GameResults> {
    return axios.get<GameResults>(WebService.baseUrl + "/games/" + gameCode + "/results",
        {
          headers: {
            "Content-Type": "application/json",
            "pw": this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while updating game");
    });
  }

  static async uploadPicture(gameCode: string, file: File): Promise<Game> {
    let formData = new FormData();
      formData.append('picture', file);
    return axios.post<Game>(WebService.baseUrl + "/games/" + gameCode + "/pictures",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "pw": this.accessCode
          },
        }
    ).then(result => {
      if (result != null) {
        Debug.log(result);
        return result.data;
      }
      throw new Error("error while creating game");
    });
  }


  static setCookie(name: string, value: string, days: number) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; SameSite=Lax";
  }

  static getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (const element of ca) {
      var c = element;
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}