import React, { useState } from 'react';
import Map, { MapLayerMouseEvent, Marker } from 'react-map-gl';
import { Coords, WLine, WMarker } from '../../types/Types';
import { LineLayer } from './LineLayer';
import { NiceMarker } from './NiceMarker';
import { StarMarker } from './StarMarker';

type MapGlProps = {
    cursor?: string,
    markers?: WMarker[],
    starmarker?: WMarker,
    lines?: WLine[],
    onClick?: (coordinates: Coords) => void
}

type MapGlState = {
    longitude: number,
    latitude: number,
    zoom?: number
}


export function MapGl(props: MapGlProps) {
    const [viewState, setViewState] = useState<MapGlState>({
        longitude: 11,
        latitude: 40,
        zoom: 0
    });


    let markers = undefined;
    if (props.markers) {

        markers = props.markers.map(marker => {
            let color = marker.color || "#0488d0";
            return <Marker key={marker.key} longitude={marker.coords.lng} latitude={marker.coords.lat} anchor="bottom" offset={[0, 9]} style={{ cursor: props.cursor || 'grab' }}>
                <NiceMarker name={marker.name} color={color} />
            </Marker>
        });
    }

    let lineLayers = undefined;
    if (props.lines) {
        let coords = props.lines.map((line, index) => line.coords);
        lineLayers = <React.Fragment>
            <LineLayer ukey={'winner'} coords={coords.slice(0, 1)} width={3} color={'gold'} />
            <LineLayer ukey={'rest'} coords={coords.slice(1)} width={1} color={'#777'} />
        </React.Fragment>;
    }

    let onClick = (evt: MapLayerMouseEvent) => {
        if (props.onClick) {
            props.onClick({ lat: evt.lngLat.lat, lng: evt.lngLat.lng });
        }
    };

    return <Map
        reuseMaps={true}
        {...viewState}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        onMove={evt => setViewState(evt.viewState)}
        onClick={onClick}
        cursor={props.cursor}
        style={{ width: '100%', height: 800 }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
    >
        {props.starmarker && 
        <Marker longitude={props.starmarker?.coords.lng} latitude={props.starmarker?.coords.lat} anchor="center" style={{ cursor: props.cursor || 'grab' }}>
            <StarMarker color={props.starmarker?.color} />
        </Marker>
        }
        {markers}
        {lineLayers}

    </Map>;
}