export abstract class DMSFormatter {
  static units = {
    degrees: '°',
    minutes: '′',
    seconds: '″',
  };

  static formatToArray(lat: number, lon: number): string[] {
    let north = lat > 0;
    let east = lon > 0;
    let latValues = this.computeFor(lat);
    let lonValues = this.computeFor(lon);

    let lat1 = this.formatFor(latValues, (north) ? 'N' : 'S' );
    let lon1 = this.formatFor(lonValues, (east) ? 'E' : 'W' );

    return [lat1, lon1];
  }

  static format(lat: number, lon: number): string {
    let north = lat > 0;
    let east = lon > 0;
    let latValues = this.computeFor(lat);
    let lonValues = this.computeFor(lon);

    let lat1 = this.formatFor(latValues, (north) ? 'N' : 'S' );
    let lon1 = this.formatFor(lonValues, (east) ? 'E' : 'W' );

    return lat1 + '   ' + lon1;
  }

  static computeFor(initValue:any): any {
    let values: any = {};
    values.initValue = initValue;
    values.degrees = Math.abs(initValue);
    values.degreesInt = Math.floor(values.degrees);
    values.degreesFrac = values.degrees - values.degreesInt;
    values.secondsTotal = 3600 * values.degreesFrac;
    values.minutes = values.secondsTotal / 60;
    values.minutesInt = Math.floor(values.minutes);
    values.seconds = values.secondsTotal - (values.minutesInt * 60);
    return values;
  }

  static formatFor(values: any, X:string): string {
    var formatted = 'DD MM ss X';
    formatted = formatted.replace(/DD/g, values.degreesInt+ this.units.degrees);
    // formatted = formatted.replace(/dd/g, values.degrees.toFixed(5)+ this.units.degrees);
    // formatted = formatted.replace(/D/g, values.degreesInt);
    // formatted = formatted.replace(/d/g, values.degrees.toFixed(5));
    formatted = formatted.replace(/MM/g, values.minutesInt+ this.units.minutes);
    // formatted = formatted.replace(/mm/g, values.minutes.toFixed(5)+ this.units.minutes);
    // formatted = formatted.replace(/M/g, values.minutesInt);
    // formatted = formatted.replace(/m/g, values.minutes.toFixed(5));
    formatted = formatted.replace(/ss/g, values.seconds.toFixed(5)+ this.units.seconds);
    // formatted = formatted.replace(/s/g, values.seconds.toFixed(5));
    //
    // formatted = formatted.replace(/-/g, (values.initValue<0) ? '-' : '');

    formatted = formatted.replace(/X/g, X);

    return formatted;
  }


}